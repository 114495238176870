//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
export default {
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },
  props: {
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      indexData: {
        type: "",
        name: "成员详情",
        xModalName: "userInfo",
      },
      userInfo: null,
    };
  },
  methods: {
    imgChange,
    /**
     * 弹窗打开回调
     */
    opened() {
      this.$nextTick(() => {
        this.loading = true;
        this.$http
          .get("/Management/UserManagement/GetUserDetail.ashx", {
            params: { usId: this.selRow.UsId },
          })
          .then((resp) => {
            this.userInfo = resp.data;
          })
          .finally(() => (this.loading = false));
      });
    },
  },
};
